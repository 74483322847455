import React, { useEffect, useMemo, useState } from "react";
import "../../../../assets/css/Table.css";
import { AiFillEye } from "react-icons/ai";
import { RiEdit2Line } from "react-icons/ri";
import { FaRupeeSign } from "react-icons/fa";
import { MdLocalPrintshop } from "react-icons/md";
import { FaRegIdCard } from "react-icons/fa";
import { Link } from "react-router-dom";
import { HiMiniPlus } from "react-icons/hi2";
import BackButton from "../../../components/backbutton/BackButton";
import Usedebounce from "../../../../dataLayer/hooks/useDebounce/Usedebounce";
import { useUserContext } from "../../../../dataLayer/hooks/useUserContext";
import { useBranchContext } from "../../../../dataLayer/hooks/useBranchContext";
import { useStudentsContext } from "../../../../dataLayer/hooks/useStudentsContext";
import Button from "../../../components/button/Button";
import { MdFilterList } from "react-icons/md";
import GateKeeper from "../../../../rbac/GateKeeper";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { ERPApi } from "../../../../serviceLayer/interceptor";
import { Offcanvas } from "bootstrap";
import { toast } from "react-toastify";
import { useLeadSourceContext } from "../../../../dataLayer/hooks/useLeadSourceContext";
import * as XLSX from 'xlsx';
import Pagination from "../../../../utils/Pagination";
import PaginationInfo from "../../../../utils/PaginationInfo";
import Filter from "../../../../utils/Filter";


function Studentdata() {

  const { studentState: { EnrolledStudents }, Dispatchstudents } = useStudentsContext();
  const { BranchState, } = useBranchContext();
  const { UsersState: { TotalUsersWithCountellers } } = useUserContext();
  const { leadSourceState } = useLeadSourceContext();
  const { debouncesetSearch, debouncesetPage } = Usedebounce(Dispatchstudents);


  const EnquirytakenByData = useMemo(() =>
    TotalUsersWithCountellers?.map((item) => ({ label: item?.fullname, value: item?.fullname })) || [],
    [TotalUsersWithCountellers]
  );

  const branchData = useMemo(() =>
    BranchState?.branches?.map((item) => ({ label: item?.branch_name, value: item?.branch_name })) || [],
    [BranchState?.branches]
  );

  const leadSourceData = useMemo(() =>
    leadSourceState?.leadSources?.map((item) => ({ label: item?.leadsource, value: item?.leadsource })) || [],
    [leadSourceState?.leadSources]
  );


  useEffect(() => {
    setFilterData(prevState => prevState.map(item => {
      if (item.inputname === "lead") {
        return { ...item, options: leadSourceData };
      }
      if (item.inputname === "enquiry") {
        return { ...item, options: EnquirytakenByData };
      }
      if (item.inputname === "branch") {
        return { ...item, options: branchData };
      }
      return item;
    }));
  }, [leadSourceData, EnquirytakenByData, branchData]);


  const initialState = [
    { label: "From Date", type: "date", inputname: "fromDate", value: "" },
    { label: "TO Date", type: "date", inputname: "toDate", value: "" },
    { label: "Lead Source", type: "select", inputname: "lead", value: "", options: [] },
    { label: "Counsellor", type: "select", inputname: "enquiry", value: "", options: [] },
    { label: "Branch", type: "select", value: "", inputname: "branch", options: [] },
    {
      label: "Mode Of Training", type: "select", inputname: "modeOfTraining", value: "", options: [
        { label: "online", value: "online" },
        { label: "offline", value: "offline" },
      ]
    }
  ];

  const [filterData, setFilterData] = useState(initialState);

  const HandleFilters = (index, name, value) => {
    let updatedFilterData = [...filterData]
    updatedFilterData[index].value = value;
    setFilterData(updatedFilterData)
  }

  const handleSearch = (e) => {
    debouncesetSearch({ context: "ENROLLED_STUDENTS", data: e.target.value });
  };

  const handlePerPage = (e) => {
    const selectedvalue = parseInt(e.target.value, 10);
    Dispatchstudents({
      type: "SET_PER_PAGE",
      payload: {
        context: "ENROLLED_STUDENTS",
        data: selectedvalue,
      },
    });
  };


  const FilterReset = () => {
    const resetFilterData = filterData?.map((item) => ({
      ...item,
      value: "",
    }))
    setFilterData(resetFilterData);
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "ENROLLED_STUDENTS",
        data: {
          fromDate: "",
          toDate: "",
          branch: "",
          enquiry: "",
          modeOfTraining: "",
          lead: "",
        },
      },
    });
  };

  const filterSubmit = () => {
    const filter = filterData.reduce((acc, item) => {
      acc[item.inputname] = item.value;
      return acc;
    }, {});
    if (!filter.fromDate && !filter.toDate && !filter.branch && !filter.modeOfTraining && !filter?.enquiry && !filter?.lead) {
      toast.error("Please fill in at least one filter criteria.");
      return;
    }

    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "ENROLLED_STUDENTS",
        data: {
          fromDate: filter?.fromDate,
          toDate: filter?.toDate,
          branch: filter?.branch,
          enquiry: filter?.enquiry,
          modeOfTraining: filter?.modeOfTraining,
          lead: filter?.lead,
        },
      },
    });

    const offcanvasElement = document.getElementById('offcanvasRight');
    const offcanvasInstance = Offcanvas.getInstance(offcanvasElement);
    offcanvasInstance.hide();
  };

  const handlePageChange = (page) => {
    debouncesetPage({ context: "ENROLLED_STUDENTS", data: page });
  }

  useEffect(() => {
    debouncesetPage({ context: "ENROLLED_STUDENTS", data: 1 });
    debouncesetSearch({ context: "ENROLLED_STUDENTS", data: "" });
    Dispatchstudents({
      type: "SET_FILTERS",
      payload: {
        context: "ENROLLED_STUDENTS",
        data: {
          fromDate: "",
          toDate: "",
          branch: "",
          enquiry: "",
          modeOfTraining: "",
          lead: "",
        },
      },
    });
    Dispatchstudents({
      type: "SET_PER_PAGE",
      payload: {
        context: "ENROLLED_STUDENTS",
        data: 10,
      },
    });
  }, []);





  // handledownloadtype 
  const [downloadType, setDownloadType] = useState();
  const [checkAll, setCheckAll] = useState(false);
  const [checkStudentid, setCheckStudentId] = useState({})
  const [studentsData, setStudentsData] = useState([])


  const handleDownloadType = (e) => {
    const downloadtype = e.target.value;
    setDownloadType(downloadtype)
  }


  const handleCheckBox = (e) => {
    const { name, value, checked } = e.target;
    setCheckStudentId((prev) => {
      if (name === "selectAll") {
        if (checked) {
          const updatedSelectedCheckboxes = {};
          EnrolledStudents.PaginatedStudents.forEach((item, index) => {
            updatedSelectedCheckboxes[item.id] = item.id;
          });
          setCheckAll(true)
          return updatedSelectedCheckboxes;
        }
        if (name === "selectAll" && !checked) {
          setCheckAll(false)
          return {};
        }
      } else {
        if (checked) {
          return {
            ...prev,
            [value]: value,
          };
        }
        if (!checked) {
          setCheckAll(false)
          const updatedState = { ...prev };
          delete updatedState[value];
          return updatedState;
        }
      }
    });
  };




  const handleDownload = async () => {
    const fetchedData = [];
    for (const id in checkStudentid) {
      const studentId = checkStudentid[id];
      try {
        // /student/getstudent_data
        const { data, status } = await axios.get(`${process.env.REACT_APP_API_URL}`);
        if (status === 200) {
          fetchedData.push(data);
        } else {
          console.error(`Failed to download data for student ID ${studentId}: Status ${status}`);
        }
      } catch (error) {
        console.error(`Error downloading data for student ID ${studentId}:`, error);
      }
    }
    setStudentsData(fetchedData);
  };

  const componentRefff = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRefff.current,
  });



  useEffect(() => {
    if (studentsData.length > 0) {
      // Call handlePrint when studentsData is updated
      handlePrint();
    }
  }, [studentsData]);

  const cellWidths = [20, 30, 150, 150, 100, 120, 130, 170, 80, 70, 90];
  const rowHeight = 40;
  const numRows = 10;


  const exportToExcel = async () => {
    const url = `${process.env.REACT_APP_API_URL}/student/list_students?page=${EnrolledStudents?.currentPage}&pageSize=${EnrolledStudents?.searchResultStudents}&search=${EnrolledStudents?.search}&filter[admissionFromDate]=${EnrolledStudents?.filters?.fromDate}&filter[admissionToDate]=${EnrolledStudents?.filters?.toDate}&filter[modeOfTraining]=${EnrolledStudents?.filters?.modeOfTraining}&filter[branch]=${EnrolledStudents?.filters?.branch}&filter[enquiryTakenby]=${EnrolledStudents?.filters?.enquiry}&filter[leadsource]=${EnrolledStudents?.filters?.lead}`
    try {
      const fetch = await ERPApi.get(url);
      console.log("fetch loading", fetch)
      const students = fetch?.data?.students || [];

      const data = students.map((student, index) => {


        console.log(student, "dfhghjfgdjfdahf")
        // Map the installments and reduce them into a single object
        const installmentDetails = student.installments.map((installment, i) => ({
          [`${i + 1} Installment`]: installment.paidamount || "N/A",
          [`${i + 1} paidDate`]: new Date(installment.paiddate).toLocaleDateString() || "N/A"
        })).reduce((acc, cur) => ({ ...acc, ...cur }), {});

        return {
          "S.No": (EnrolledStudents.currentPage - 1) * EnrolledStudents.searchResultStudents + index + 1,
          "Student Name": student.name,
          Counsellor: student.enquirytakenby,
          CourseName: student.courses,
          AdmissionDate:student.admissiondate,
          AdmissionFee: `${student.initialpayment[0]?.initialamount}`,
          TotalAmount: student.finaltotal,
          PaidAmount: student.totalpaidamount,
          DueAmount: student.dueamount,
          ...installmentDetails // Spread the mapped installment details here
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(data);
      const columnWidths = [
        { wpx: 50 }, // S.No
        { wpx: 150 }, // Student Name
        { wpx: 150 }, // Counsellor
        { wpx: 150 }, // Counsename    

        { wpx: 70 }, // AdmissionFee
        { wpx: 100 }, // TotalAmount
        { wpx: 100 }, // Totalpaidamount
        { wpx: 100 },//DueAmount
        { wpx: 150 },//1 Installment Amount
        { wpx: 150 },//1 Installment date
        { wpx: 150 },//2 Installment Amount
        { wpx: 150 },//2 Installment date
        { wpx: 150 },//3 Installment Amount
        { wpx: 150 },//3 Installment date
        { wpx: 150 },//4 Installment Amount
        { wpx: 150 },//4 Installment date
        { wpx: 150 },//5 Installment Amount
        { wpx: 150 },//5 Installment date
        { wpx: 150 },//6 Installment Amount
        { wpx: 150 },//6 Installment date
        { wpx: 150 },//7 Installment Amount
        { wpx: 150 },//7 Installment date
        { wpx: 150 },//8 Installment Amount
        { wpx: 150 },//8 Installment date
      ];



      worksheet['!cols'] = columnWidths;

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Students");
      XLSX.writeFile(workbook, "EnrolledStudents.xlsx");
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  return (
    <div>
      <BackButton heading="Enrolled Students " content="Back" />
      <div className="container-fluid">
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Assign the Batch</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label className="form-label fs-s fw-medium black_300">
                        Branch<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control fs-s bg-form text_color input_bg_color"
                        aria-label=""
                        placeholder=""
                        name="branch"
                        id="branch"
                        required
                      >
                        <option value="" disabled className="">
                          Select
                        </option>
                        <option value="" selected className="">
                          Ameerpet
                        </option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label fs-s fw-medium black_300">
                        Trainer Name<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control fs-s bg-form text_color input_bg_color"
                        aria-label=""
                        placeholder=""
                        name="branch"
                        id="branch"
                        required
                      >
                        <option value="" disabled selected className="">
                          Select
                        </option>
                        <option value="" selected className="">
                          Ammu
                        </option>
                      </select>
                    </div>

                    <div className="mb-3">
                      <label
                        for="firstNameinput"
                        className="form-label fs-s fw-medium black_300"
                      >
                        Batch Time<span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        className="form-control fs-s bg-form text_color input_bg_color"
                        id=""
                        name=""
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label className="form-label fs-s fw-medium black_300">
                        Course<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control fs-s bg-form text_color input_bg_color"
                        aria-label=""
                        placeholder=""
                        name="branch"
                        id="branch"
                        required
                      >
                        <option value="" disabled selected className="">
                          Select
                        </option>
                        <option value="" selected className="">
                          python
                        </option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label
                        for="firstNameinput"
                        className="form-label fs-s fw-medium black_300"
                      >
                        {" "}
                        Batch Start Date<span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control fs-s bg-form text_color input_bg_color"
                        id=""
                        name=""
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label fs-s fw-medium black_300">
                        Training Mode<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control fs-s bg-form text_color input_bg_color"
                        aria-label=""
                        placeholder=""
                        name="branch"
                        id="branch"
                        required
                      >
                        <option value="" disabled selected className="">
                          Select
                        </option>
                        <option value="" className="">
                          Offline
                        </option>
                        <option value="" className="">
                          Online
                        </option>
                        <option value="" className="">
                          Hybrid
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="mt-2 text-end">
                    <button type="button" class="btn btn-sm btn-md btn_primary fs-13">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row response">
          <div className="col-xl-12">
            <div className="card border-0">
              <div className="card-header">
                <div className=" row d-flex justify-content-between">
                  <div className="col-sm-4">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search input_bg_color text_color"
                        placeholder="Search for..."
                        name="search"
                        required
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 response-btn">
                    {/* Download button */}
                    <div className="buttons_alignment ">

                      {/* <button type="button" class="btn btn-sm btn_primary margin_top_12 me-1 button-res" data-bs-toggle="modal" data-bs-target="#myModal"><MdFileDownload className="me-1" />Download</button> */}

                      <div id="myModal" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true">

                        <div class="modal-dialog modal-dialog-centered">
                          <div class="modal-content">
                            <div class="modal-header ">
                              <h5 class="modal-title" id="myModalLabel">Data Type</h5>
                              <button type="button" class="btn-close mt-2 " data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            {/* <div class="modal-body fs-s">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                  Enrollement Data(CSV)
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                  Fee Receipt
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                  Fee Invoice
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                  Admission Form
                                </label>
                              </div>
                            </div> */}

                            <div class="modal-body fs-s">
                              <div class="form-check">

                              </div>
                              <select
                                className="form-select form-control input_bg_color text_color select"
                                aria-label="Default select example"
                                placeholder="selectDownloadtype"
                                name="downlaodtype"
                                value={downloadType}
                                onChange={handleDownloadType}
                                // id="enquiryTakenBy"
                                // name="enquiryTakenBy"
                                // value={filterCriteria?.enquiryTakenBy}
                                // onChange={HandleFilterCriteria}
                                required
                              >
                                <option value="" disabled selected>
                                  {" "}
                                  Select Download Type{" "}
                                </option>
                                <option value="Admission Form">Admission Form </option>
                                <option value="Fee Invoice"> Fee Invoice </option>
                                <option value="Enrollement Data(CSV)">Enrollement Data(CSV) </option>
                              </select>

                              <div class="form-check">

                              </div>

                            </div>
                            <div class="modal-footer fs-s">
                              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                              <button type="button" class="btn btn_primary "
                                onClick={handleDownload}
                              >Download</button>
                            </div>
                          </div>
                        </div>

                      </div>
                      <button
                        className="btn btn-sm btn_primary fs-13 me-1  margin_top_12 button-res"
                        type="button"

                        onClick={() => exportToExcel()}
                      >

                        Export
                      </button>
                      <button
                        className="btn btn-sm btn_primary fs-13 me-1  margin_top_12 button-res"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                      >
                        <MdFilterList className="me-1 mb-1" />
                        Filters
                      </button>
                      <GateKeeper requiredModule="Student Management" submenumodule="Enrolled Students" submenuReqiredPermission="canCreate">
                        <Button
                          type="button"
                          className="btn btn-sm btn_primary fs-13 mt-2 margin_top_12 button-res"
                        >
                          <Link to="/student/new" className="button_color ">
                            {<HiMiniPlus />} Add Enrollment
                          </Link>
                        </Button>
                      </GateKeeper>


                    </div>

                  </div>
                </div>
                <div
                  className="offcanvas offcanvas-end  bg_white"
                  id="offcanvasRight"
                  aria-labelledby="offcanvasRightLabel"
                >

                  <div className="offcanvas-header ">
                    <h5
                      className="offcanvas-title  text_color"
                      id="offcanvasRightLabel"
                    >
                      Filters
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="offcanvas-body p-2 bg_white">
                    <Filter filterData={filterData} HandleFilters={HandleFilters} filterReset={FilterReset} filterSubmit={filterSubmit} />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive table-card table-container table-scroll border-0">

                  <table className="table table-centered align-middle  table-nowrap equal-cell-table table-hover">
                    <thead>
                      <tr className="">
                        {/* <th scope="col">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="cardtableCheck"
                            />
                            <label
                              className="form-check-label"
                              for="cardtableCheck"
                            ></label>
                          </div>
                        </th> */}

                        {/* download  present work*/}

                        {/* <th>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="selectAll"
                            checked={checkAll}
                            onChange={handleCheckBox}
                          />
                        </th> */}
                        <th
                          scope="col"
                          className="fs-13 lh-xs fw-600  "
                        >
                          S.No
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600  "
                        >
                          Student&nbsp;Name
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600  text-truncate" title="Registration Number" style={{ maxWidth: "120px" }}
                        >
                          Registration&nbsp;Number
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600  "
                        >
                          Branch
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs fw-600  "
                        >
                          Course
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs fw-600 "
                        >
                          Counsellor
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600 "
                        >
                          Mobile
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600 "
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600 text-truncate" title=" Training Mode" style={{ maxWidth: "70px" }}
                        >
                          Joining&nbsp;Date

                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600 text-truncate" title=" Training Mode" style={{ maxWidth: "70px" }}
                        >
                          Training&nbsp;Mode
                        </th>
                        <th
                          scope="col"
                          className="fs-13 lh-xs  fw-600 action-column"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">


                      {EnrolledStudents.PaginatedStudents &&
                        EnrolledStudents.PaginatedStudents.length > 0 ? (
                        EnrolledStudents.loading ? (
                          <tr>
                            <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                              Loading...

                            </td>
                          </tr>
                        ) : (
                          EnrolledStudents.PaginatedStudents.map(
                            (item, index) => {
                              let date = new Date(item.admissiondate);
                              const day = date.getUTCDate();
                              const monthIndex = date.getUTCMonth();
                              const year = date.getUTCFullYear();
                              const monthAbbreviations = [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                              ];
                              date = `${day < 10 ? "0" : ""}${day}-${monthAbbreviations[monthIndex]
                                }-${year}`;

                              return (

                                <tr key={item.id}>
                                  {/* <td>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name={index + 1}
                                      value={item.id}
                                      checked={checkStudentid[item.id] ? true : false}
                                      onChange={handleCheckBox}
                                    />
                                  </td> */}
                                  <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                                    {(EnrolledStudents?.currentPage - 1) *
                                      EnrolledStudents.perPage +
                                      index +
                                      1}
                                  </td>
                                  <td
                                    className="fs-13 black_300  lh-xs bg_light text-truncate"
                                    style={{ maxWidth: "150px" }}
                                    title={item.name}
                                  >
                                    {item.name}
                                  </td>
                                  <td className="fs-13 black_300  lh-xs bg_light">
                                    {item.registrationnumber}
                                  </td>
                                  <td className="fs-13 black_300  lh-xs bg_light">
                                    {item.branch}
                                  </td>
                                  <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "120px" }} title={item.courses} >
                                    {item.courses}
                                  </td>
                                  <td className="fs-13 black_300  lh-xs bg_light text-truncate" style={{ maxWidth: "120px" }} title={item.enquirytakenby}>
                                    {item.enquirytakenby}
                                  </td>
                                  <td className="fs-13 black_300  lh-xs bg_light ">
                                    {item.mobilenumber}
                                  </td>
                                  <td
                                    className="fs-13 black_300  lh-xs bg_light text-truncate"
                                    style={{ maxWidth: "150px" }}
                                    title={item?.email}
                                  >
                                    {item.email}
                                  </td>
                                  <td className="fs-13 black_300 lh-xs bg_light text-truncate" title={item.date} style={{ maxWidth: "100px" }}>
                                    {date ? date : "No Date"}
                                  </td>
                                  <td className="fs-13 black_300 lh-xs bg_light">
                                    {item.modeoftraining}
                                  </td>
                                  <td className="fs-14 text_mute bg_light lh-xs">
                                    <GateKeeper requiredModule="Student Management" submenumodule="Enrolled Students" submenuReqiredPermission="canRead">
                                      <Link to={`/student/view/${item.id}`}>
                                        {" "}
                                        <AiFillEye className="eye_icon table_icons me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="view" />
                                      </Link>
                                    </GateKeeper>

                                    {/* <span data-bs-toggle="modal" data-bs-target="#exampleModal">
                                      <HiMiniPlus className="eye_icon fw-500 table_icons me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Add Batch" />
                                    </span> */}


                                    <GateKeeper requiredModule="Student Management" submenumodule="Enrolled Students" submenuReqiredPermission="canUpdate">
                                      <Link
                                        to={`/student/editstudent/${item.id}`}
                                      >
                                        {" "}
                                        <RiEdit2Line className="edit_icon table_icons me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" />{" "}
                                      </Link>
                                    </GateKeeper>

                                    <GateKeeper requiredModule="Student Management" submenumodule="Fee Details" submenuReqiredPermission="canUpdate">
                                      <Link to={`/student/feeview/${item.id}`}>
                                        <FaRupeeSign className="rupee_icon table_icons me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Rupee" />
                                      </Link>
                                    </GateKeeper>

                                    <GateKeeper requiredModule="Student Management" submenumodule="Enrolled Students" submenuReqiredPermission="canRead">
                                      <Link
                                        to={`/student/applicationprint/${item.id}`}
                                      >
                                        {" "}
                                        <MdLocalPrintshop className="text-mute table_icons me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Print" />{" "}
                                      </Link>
                                    </GateKeeper>

                                    <GateKeeper requiredModule="Student Management" submenumodule="Enrolled Students" submenuReqiredPermission="canRead">
                                      <Link
                                        to={`/student/studentidcard/${item.id}`}
                                      >
                                        {" "}
                                        <FaRegIdCard className="id_card table_icons" data-bs-toggle="tooltip" data-bs-placement="top" title="ID Card" />
                                      </Link>
                                    </GateKeeper>

                                  </td>
                                </tr>
                              );
                            }
                          )
                        )
                      ) : (
                        <tr>
                          <td className="fs-13 black_300 fw-500 lh-xs bg_light ">
                            No data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="mt-3 align-items-center d-flex justify-content-between row text-center text-sm-start ">
                  <div className="col-sm">

                    <PaginationInfo
                      data={{
                        length: EnrolledStudents?.PaginatedStudents?.length,
                        start: EnrolledStudents?.startStudent,
                        end: EnrolledStudents?.endStudent,
                        total: EnrolledStudents?.searchResultStudents,
                      }}
                      loading={EnrolledStudents?.loading}
                    />

                  </div>
                  <div className="col-sm-auto mt-3 mt-sm-0  d-flex">
                    <div className="mt-2">
                      <select
                        className="form-select form-control me-3 input_bg_color  pagination-select"
                        aria-label="Default select example"
                        placeholder="Branch*"
                        name="branch"
                        id="branch"
                        required
                        onChange={handlePerPage}
                        value={EnrolledStudents?.perPage}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className="">
                      <Pagination
                        currentPage={EnrolledStudents?.currentPage}
                        totalPages={EnrolledStudents?.totalPages}
                        loading={EnrolledStudents?.loading}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Studentdata;
